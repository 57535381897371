var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-tile",
    { attrs: { title: "Sessions", "full-width": "" } },
    [
      _c(
        "v-list",
        {
          staticStyle: { "overflow-y": "auto" },
          attrs: {
            dense: "",
            "two-line": "",
            "min-height": 225,
            "max-height": 225
          }
        },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { value: _vm.selectedSession, color: "primary" },
              on: { change: _vm.onSessionSelect }
            },
            [
              _vm._l(_vm.sessions, function(item, index) {
                return [
                  _c(
                    "v-list-item",
                    { key: index, staticClass: "align-center" },
                    [
                      _c(
                        "v-list-item-avatar",
                        [_c("v-icon", [_vm._v("mdi-calendar")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "justify-start text-start px-0 mx-0" },
                        [
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(item.name) }
                          }),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              _vm._s(item.start_date) +
                                " - " +
                                _vm._s(item.end_date)
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _vm.selectedSession === index
                            ? _c("v-icon", [_vm._v("mdi-check-circle")])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  index < _vm.sessions.length - 1
                    ? _c("v-divider", { key: "divider-" + index })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }