<template>
    <card-tile title="Sessions" full-width>
        <v-list
            dense
            two-line
            :min-height="225"
            :max-height="225"
            style="overflow-y: auto"
        >
            <v-list-item-group
                :value="selectedSession"
                color="primary"
                @change="onSessionSelect"
            >
                <template v-for="(item, index) in sessions">
                    <v-list-item :key="index" class="align-center">
                        <v-list-item-avatar>
                            <v-icon>mdi-calendar</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content
                            class="justify-start text-start px-0 mx-0"
                        >
                            <v-list-item-title
                                v-text="item.name"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                                >{{ item.start_date }} -
                                {{ item.end_date }}</v-list-item-subtitle
                            >
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon v-if="selectedSession === index"
                                >mdi-check-circle</v-icon
                            >
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider
                        :key="`divider-${index}`"
                        v-if="index < sessions.length - 1"
                    ></v-divider>
                </template>
            </v-list-item-group>
        </v-list>
    </card-tile>
</template>
<script>
export default {
    name: "session-select",
    components: {
        CardTile: () => import("../components/Dashboard/CardTile"),
    },
    props: {
        sessions: {
            type: Array,
            required: true,
        },
        session: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        selectedSession() {
            return this.sessions.findIndex(
                (session) => session.id === this.session.id
            );
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        onSessionSelect(index) {
            this.$emit("update:session", this.sessions[index]);
        },
    },
};
</script>

<style scoped>
</style>